import "./App.css";
import React, { useState, useEffect } from "react";

function App() {
  const APP_LIST_GET = process.env.REACT_APP_LIST_API;
  const RANDOM_APP_GET = process.env.REACT_APP_RANDOM_API;

  const [app, setApp] = useState([]);
  const [loading, setLoading] = useState(true);

  function SteamButton({ appId }) {
    const link = `https://store.steampowered.com/app/${appId}/`;
    return (
      <div>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img
            src={process.env.PUBLIC_URL + "/icons8-steam-64.png"}
            alt="Steam Icon"
          />
        </a>
      </div>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(RANDOM_APP_GET, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setApp(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors or set an error state if needed
      }
    };

    fetchData(); // Call the async function
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {/* <h1>God help me decide</h1> */}
        {loading ? (
          <p>God is helping...</p>
        ) : (
          <div className="App">
            <img src={app.headerImage} alt="app Header" />
            <h1>{app.name}</h1>
            <p>{app.description}</p>
            <SteamButton appId={app.appId} />
          </div>
        )}
      </header>
      <footer></footer>
    </div>
  );
}

export default App;
